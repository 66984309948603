import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import Button from 'components/Button';

import crossSVG from './img/cross.svg';
import './ActiveWithdrawalRequestsModal.scss';
import Select from 'components/Select';

const b = block('active-withdrawal-request-modal');

const ActiveWithdrawalRequestsModal = ({ item, note, onNoteChange, onClose, onSend, areYouSure, onAreYouSure, onApproveRequest, activeSelect, selectItems, handleChangeSelect }) => {
  const locale = useSelector(state => state.locale.locale);

  if (item === null) return null;

  return (
    <section className={b()}>
      <div className={b('content')}>
        {areYouSure.sureReject && (
          <>
            <div className={b('header')}>{`${locale.sureRejWithdraw} "Withdrawal ID - ${item.id}" ?`}</div>
            <div className={b('buttons')}>
              <div className={b('button')}>
                <Button color='grey-border' onClick={() => onAreYouSure('sureReject')}>
                  {locale.yesReject}
                </Button>
              </div>
              <div className={b('button')}>
                <Button onClick={() => { onClose(); onAreYouSure('sureReject') }}>
                  {locale.cancel}
                </Button>
              </div>
            </div>
          </>
        )}
        {!areYouSure.sureReject && !areYouSure.sureWithdrawal && (
          <>
            <div className={b('header')}>
              {locale.requestRejection}
              {/*<SVGInline className={b('cross').toString()} onClick={onClose} svg={crossSVG} />*/}
            </div>
            <div className={b('reject')}>
              {locale.youRejected}
              <span className={b('amount')}>{item.amount}</span>
            </div>
            <div className={b('comment')}>{locale.reasonForRejection}<span className={b('required')}>*</span></div>
            <div className={b('selector')}>
              <Select
                items={selectItems}
                activeItem={activeSelect}
                onChange={handleChangeSelect}
                placeholder={<span className={b('grey')}>{locale.reasonForRejection}</span>}
              />
            </div>
            <div className={b('comment')}>{locale.comment}<span className={b('required')}>*</span></div>
            <textarea
              className={b('note')}
              rows={8}
              value={note}
              placeholder={locale.writeExplanation}
              onChange={e => onNoteChange(e.currentTarget.value)}
            />
            <div className={b('button')}>
              <Button onClick={onSend} disabled={!Boolean(note) || !Boolean(activeSelect)}>
                {locale.send}
              </Button>
            </div>
          </>
        )}

        {areYouSure.sureWithdrawal && (
          <>
            <div className={b('header')}>{`${locale.sureAprWithdraw} "Withdrawal ID - ${item.id}" ?`}</div>
            <div className={b('buttons')}>
              <div className={b('button')}>
                <Button color='grey-border' onClick={() => { onAreYouSure('sureWithdrawal'); onApproveRequest(item.id); onClose() }}>
                  {locale.yesConfirm}
                </Button>
              </div>
              <div className={b('button')}>
                <Button onClick={() => { onClose(); onAreYouSure('sureWithdrawal') }}>
                  {locale.cancel}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ActiveWithdrawalRequestsModal;
