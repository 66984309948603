import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'paymentAdmin/ACTION_PROCESSING',
  GET_REQUISITES_SUCCESS: 'paymentAdmin/GET_REQUISITES_SUCCESS',
  SET_SELECTED_REQUISITE: 'paymentAdmin/SET_SELECTED_REQUISITE',
  GET_BANKS_SUCCESS: 'paymentAdmin/GET_BANKS_SUCCESS',
  GET_ACTIVE_REQUESTS_SUCCESS: 'payment/GET_ACTIVE_REQUESTS_SUCCESS',
  GET_REQUESTS_HISTORY_SUCCESS: 'payment/GET_REQUESTS_HISTORY_SUCCESS',
  GET_WITHDRAWAL_REQUESTS_HISTORY_SUCCESS: 'payment/GET_WITHDRAWAL_REQUESTS_HISTORY_SUCCESS',
  GET_BANK_WITHDRAWALS: 'payment/GET_BANK_WITHDRAWALS',
  GET_CASHIERS_REQUISITES: 'payment/GET_CASHIERS_REQUISITES',
  GET_DEPOSITS_P2P: 'payment/GET_DEPOSITS_P2P',
  GET_ACTIVE_P2P_WITHDRAWALS: 'payment/GET_ACTIVE_P2P_WITHDRAWALS',
};

const getRequisites = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.getRequisites();
  if (response.success) {
    dispatch({ type: actionTypes.GET_REQUISITES_SUCCESS, payload: response.data });
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const setRequisite = ({ id, requisite }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.setRequisite({ id, requisite });
  if (response.success) {
    dispatch(addNotify(locale.requisitesChanged, 'success'));
    dispatch(getRequisites());
  } else {
    const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
    dispatch(addNotify(errorMessage, 'error'));
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const setSelectedRequisite = requisiteId => async dispatch => {
  dispatch({ type: actionTypes.SET_SELECTED_REQUISITE, payload: requisiteId });
};

const removeRequisite = ({ id, callback }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.removeRequisite({ id });
  if (response.success) {
    dispatch(addNotify(locale.defaultSuccess, 'success'));
    if (callback) callback();
  } else {
    const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
    dispatch(addNotify(errorMessage, 'error'));
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const getBanks = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.getBanks();
  if (response.success) {
    dispatch({ type: actionTypes.GET_BANKS_SUCCESS, payload: response.data });
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const createRequisite = ({ bank, requisite, callback }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.createRequisite({ bank, requisite });
  if (response.success) {
    dispatch(addNotify(locale.defaultSuccess, 'success'));
    if (callback) callback();
  } else {
    const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
    dispatch(addNotify(errorMessage, 'error'));
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const getActiveRequests = ({ count, page, transactionId }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.getActiveRequests({ count, page, transactionId });
  if (response.success) {
    dispatch({ type: actionTypes.GET_ACTIVE_REQUESTS_SUCCESS, payload: response.data });
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const setRequest = ({ id, status, comment, amount, callback }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.setRequest({ id, status, comment, amount });
  if (response.success) {
    dispatch(addNotify(locale.defaultSuccess, 'success'));
    if (callback) callback();
  } else {
    const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
    dispatch(addNotify(errorMessage, 'error'));
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const getRequestsHistory = ({ from, to, count, page, status }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.getRequestsHistory({ from, to, count, page, status });
  if (response.success) {
    dispatch({ type: actionTypes.GET_REQUESTS_HISTORY_SUCCESS, payload: response.data });
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const getWithdrawalRequestsHistory = ({ from, to, count, page, status }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.paymentAdmin.getWithdrawalRequestsHistory({ from, to, count, page, status });
  if (response.success) {
    dispatch({ type: actionTypes.GET_WITHDRAWAL_REQUESTS_HISTORY_SUCCESS, payload: response.data });
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const getBankWithdrawals = ({ status }) => async (dispatch, getState, extra) => {
  const { api } = extra;

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

  const response = await api.paymentAdmin.getBankWithdrawals({ status });

  if (response.success) {
    dispatch({ type: actionTypes.GET_BANK_WITHDRAWALS, payload: response.data });
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const changeBankWithdrawalStatus = ({ id, status, transactionId }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

  const response = await api.paymentAdmin.changeBankWithdrawalStatus({ id, status, transactionId });

  if (response.success) {
    dispatch(addNotify(locale.statusSuccessfullyChanged, 'success'));
    dispatch(getBankWithdrawals({}));
  } else {
    const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
    dispatch(addNotify(errorMessage, 'error'));
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

const getCashierRequisites = () =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.getCashierRequisites();

    if (response.success) {
      dispatch({ type: actionTypes.GET_CASHIERS_REQUISITES, payload: response.data });
    } else {
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };

const createCashierRequisite = (info, callback) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.createCashierRequisite(info);

    if (response.success) {
      dispatch(addNotify(locale.defaultSuccess, 'success'));
      dispatch(getCashierRequisites());
      if(callback) callback()
    } else {
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  }

const patchCashierRequisite = (info) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.patchCashierRequisite(info);

    if (response.success) {
      dispatch(addNotify(locale.defaultSuccess, 'success'));
      dispatch(getCashierRequisites());
    } else {
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  }

const deleteCashierRequisite = (id, callback) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.deleteCashierRequisite(id);

    if (response.success) {
      dispatch(addNotify(locale.defaultSuccess, 'success'));
      dispatch(getCashierRequisites());
      if(callback) callback()
    } else {
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  }

const getDepositsP2P = ({ count, page, from, to, status }) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.getDepositsP2P({ count, page, from, to, status });

    if (response.success) {
      dispatch({ type: actionTypes.GET_DEPOSITS_P2P, payload: response.data });
    } else {
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  }

const patchRequestStatusP2P = ({ id, status, comment, amount, callback }) => 
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.patchRequestStatusP2P({ id, status, comment, amount });

    if (response.success) {
      dispatch(addNotify(locale.defaultSuccess, 'success'));
      if (callback) callback();
    } else { 
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  }
  
const getActiveP2Pwithdrawals = ({ count, page, from, to, status }) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.getActiveP2Pwithdrawals({ count, page, from, to, status });

    if (response.success) {
      dispatch({ type: actionTypes.GET_ACTIVE_P2P_WITHDRAWALS, payload: response.data });
    } else {
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  }

const patchActiveP2Pwithdrawals = ({ id, status, comment, callback }) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.paymentAdmin.patchActiveP2Pwithdrawals({ id, status, comment });

    if (response.success) {
      dispatch(addNotify(locale.defaultSuccess, 'success'));
      if (callback) callback();
    } else {
      const errorMessage = locale[response?.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  }


export {
  actionTypes,
  getRequisites,
  setRequisite,
  setSelectedRequisite,
  removeRequisite,
  getBanks,
  createRequisite,
  getActiveRequests,
  setRequest,
  getRequestsHistory,
  getBankWithdrawals,
  changeBankWithdrawalStatus,
  getWithdrawalRequestsHistory,
  getCashierRequisites,
  createCashierRequisite,
  patchCashierRequisite,
  deleteCashierRequisite,
  getDepositsP2P,
  getActiveP2Pwithdrawals,
  patchActiveP2Pwithdrawals,
  patchRequestStatusP2P,
};
