import React from "react";
import "./ToggleSwitch.scss";
import block from "bem-cn";

const b = block("toggle");

export default function ToggleSwitch({ isChecked, setIsChecked }) {
  return (
    <label className={b()}>
      <input
        type="checkbox"
        className={b("input")}
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      <div className={b("slider")}>
        <div className={b("circle")}>
          <div className={b("circle-inner")}></div>
        </div>
      </div>
    </label>
  );
}
