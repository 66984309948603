import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';

import { actions } from 'features/PaymentAdmin';

export const useActiveDepositRequests = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { actionProcessing, activeDeposits } = useSelector(state => state.paymentAdmin, shallowEqual);
  const { currency } = useSelector(state => state.auth, shallowEqual);
  const [areYouSure, setAreYouSure] = useState({
    sureDeposit: false,
    sureReject: false,
  });

  const [values, setValues] = useState({
    note: '',
    page: 0,
    count: 7,
  });
  
  const [activeItem, setActiveItem] = useState(null);
  const [activeReceipt, setActiveReceipt] = useState(null);

  const pages = Math.ceil(activeDeposits.deposits.length / values.count);

  const items = activeDeposits.deposits.slice(values.page * values.count, (values.page + 1) * values.count).map(item => ({
    ...item,
    date: dayjs(item.date).format('DD.MM.YYYY HH:mm'),
    amount: item.amount,
  }));

  const handleNoteChange = value => {
    setValues(ps => ({
      ...ps,
      note: value,
    }));
  };

  const handleAreYouShure = (key) => {
    setAreYouSure(ps => ({
      ...ps,
      [key]: !ps[key],
    }));
  }

  const handleItemOpen = item => {
    setActiveItem(item);
  };

  const handleOpenReceipt = item => {
    setActiveReceipt(item);
  };

  const handleItemClose = () => {
    setActiveItem(null);
    setValues(ps => ({ ...ps, note: '' }));
  };

  const handleReceiptClose = () => {
    setActiveReceipt(null);
  };

  const handlePageChange = page => {
    setValues(ps => ({ ...ps, page }));
  };
  const handleActiveRequestsGet = () => {
    dispatch(
      actions.getDepositsP2P({
        page: values.page,
        count: values.count,
        status: 0,
        from: dayjs().subtract(7, 'day').startOf('day').toDate(),
        to: dayjs().endOf('day').toDate(),
      }),
    );
  };

  const handleRequestApprove = ({ id }) => {
    dispatch(
      actions.patchRequestStatusP2P({
        id,
        amount: activeItem.amount,
        comment: values.note,
        status: 1,
        callback: () => {
          setValues(ps => ({ ...ps, note: '' }));
          handleActiveRequestsGet();
        },
      }),
    );
  };

  const handleRequestReject = () => {
    dispatch(
      actions.patchRequestStatusP2P({
        id: activeItem.id,
        comment: values.note,
        amount: activeItem.amount,
        status: 2,
        callback: () => {
          setValues(ps => ({ ...ps, note: '' }));
          setActiveItem(null);
          handleActiveRequestsGet();
        },
      }),
    );
  };

  useEffect(() => {
    handleActiveRequestsGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.page]);

  return {
    input: {
      values,
      areYouSure,
      activeItem,
      activeReceipt,
      handleNoteChange,
      handleRequestApprove,
      handleRequestReject,
      handleItemOpen,
      handleItemClose,
      handlePageChange,
      handleOpenReceipt,
      handleReceiptClose,
      handleAreYouShure,
    },
    output: {
      locale,
      actionProcessing,
      items,
      pages,
      currency,
    },
  };
};
