import React from "react";
import block from "bem-cn";

import { usePaymentMethods } from "features/PaymentAdmin/model/usePaymentMethods";

import Button from "components/Button";
import SVG from "components/SVG";
import Input from "components/Input/desktop";
import InputPhoto from "components/InputPhoto/desktop";
import ToggleSwitch from "components/Toggle/desktop";
import Spinner from "components/Spinner";

import PaymentMethodsItem from "./PaymentMethodsItem/PaymentMethodsItem";

import "./PaymentMethods.scss";
import plusSVG from "./img/Plus.svg";
import PaymentMethodsModal from "./PaymentMethodsModal/PaymentMethodsModal";

const b = block('payment-methods');

const PaymentMethods = () => {
  const {
    locale,
    cashierRequisites,
    actionProcessing,
    addMethod,
    values,
    requeuedInputs,
    onClickAddMethod,
    onChangeLogo,
    onChangeQr,
    handleDeleteMethod,
    onSubmitForm,
    handleItemClick,
    setValues,
    areYouSure,
    handleAreYouShure,
  } = usePaymentMethods();

  const items = cashierRequisites.map(item => (
    <PaymentMethodsItem onItemClick={handleItemClick} item={item} key={item.id} />
  ));

  return (
    <>
      <Spinner isLoading={actionProcessing} />
      <PaymentMethodsModal isOpen={areYouSure} item={values} handleAreYouShure={handleAreYouShure} onAccept={() => handleDeleteMethod(values.id)} />
      <div className={b()}>
        <div className={b('title')}>{locale.paymentMethod}</div>

        <div className={b('container')}>
          <div className={b('left-block')}>
            <div className={b('block-header')}>
              <div className={b('title-2')}>{locale.payment}</div>
              <div className={b('button')}>
                <Button onClick={onClickAddMethod}>
                  <div className={b('button-text')}>
                    <SVG className={b('button-icon')} svgProps={{ svg: plusSVG }} />
                    <div>{locale.addNewMethod}</div>
                  </div>
                </Button>
              </div>
            </div>

            <div className={b('items-container')}>
              {items.length === 0 && <div className={b('null-descr')}>{locale.andNewMethod}</div>}
              {items}
            </div>
          </div>

          <div className={b('right-block')}>
            <div className={b('block-header')}>
              <div className={b('title-2')}>{locale.requisites}</div>
            </div>

            <div className={b('items-container')}>
              {!addMethod && <div className={b('null-descr')}>{locale.chooseToEdit}</div>}
              {addMethod && (
                <form onSubmit={onSubmitForm} className={b('form')}>
                  <div className={b('form-header')}>
                    <div className={b('from-title')}>{locale.paymentActive}</div>
                    <ToggleSwitch
                      isChecked={values.active}
                      setIsChecked={() => setValues({ ...values, active: !values.active })}
                    />
                  </div>

                  <div className={b('form-inputs')}>
                    <div className={b('items')}>
                      <div className={b('item')}>
                        <div className={b('item-title')}>{locale.name}<span className={b('required')}>*</span></div>
                        <div className={b('search-block')}>
                          <Input
                            value={values.name}
                            placeholder='Name'
                            type='text'
                            onChange={e => setValues({ ...values, name: e.target.value })}
                          />
                        </div>
                      </div>

                      <div className={b('item')}>
                        <div className={b('item-title')}>{locale.uploadLogo}</div>
                        <div className={b('search-block')}>
                          <InputPhoto
                            withIcon
                            name="photo"
                            placeholder={locale.uploadLogo}
                            currentImageData={values.logo}
                            onImageChange={onChangeLogo}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={b('items')}>
                      <div className={b('item')}>
                        <div className={b('item-title')}>{locale.paymentNumber}<span className={b('required')}>*</span></div>
                        <div className={b('search-block')}>
                          <Input
                            value={values.requisite}
                            placeholder={locale.paymentNumber}
                            type='text'
                            onChange={e => setValues({ ...values, requisite: e.target.value })}
                          />
                        </div>
                      </div>

                      <div className={b('item')}>
                        <div className={b('item-title')}>QR</div>
                        <div className={b('search-block')}>
                          <InputPhoto
                            withIcon
                            name="photo"
                            placeholder={locale.loadQR}
                            currentImageData={values.qr}
                            onImageChange={onChangeQr}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={b('items-buttons')}>
                      <div className={b('form-button')}>
                        <Button disabled={values.new} onClick={handleAreYouShure} color="grey-border">
                          {locale.deleteDetails}
                        </Button>
                      </div>
                      <div className={b('form-button')}>
                        <Button disabled={requeuedInputs} type="submit">Save</Button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethods;
