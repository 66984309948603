import BaseApi from '../BaseApi';
import { PaymentAdminConverter } from './PaymentAdminConverter';

class PaymentAdminApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.apiUrl = `${baseUrl}/api`;
    this.converter = new PaymentAdminConverter();
  }

  getRequisites = () => this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/admin_requisite`);

  setRequisite = ({ id, requisite }) =>
    this.sendQuery(this.queryTypes.PUT, `${this.baseUrl}/admin_requisite`, {
      requisite_id: id,
      payment_requisite: requisite,
    });

  removeRequisite = ({ id }) =>
    this.sendQuery(this.queryTypes.DELETE, `${this.baseUrl}/admin_requisite`, {
      requisite_id: id,
    });

  getBanks = () =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.apiUrl}/site/admin_payment_systems`,
      null,
      null,
      this.converter.convertBanks,
    );

  createRequisite = ({ bank, requisite }) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/admin_requisite`, {
      payment_system: bank,
      payment_requisite: requisite,
    });

  getActiveRequests = ({ count, page, transactionId }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/admin_deposit`,
      { count, page, id_of_the_transaction: transactionId },
      null,
      this.converter.convertActiveRequests,
    );

  setRequest = ({ id, status, comment, amount }) =>
    this.sendQuery(this.queryTypes.PATCH, `${this.baseUrl}/admin_deposit`, { id, status, comment, amount });

  getRequestsHistory = ({ from, to, count, page, status }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/admin_deposit/history`,
      { count, page, date_from: from, date_to: to, status },
      null,
      this.converter.convertRequestsHistory,
    );

  getWithdrawalRequestsHistory = ({ from, to, count, page, status }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/cashier_withdrawal_by_payment/history`,
      { count, page, date_from: from, date_to: to, status },
      null,
      this.converter.convertWithdrawalRequestsHistory,
    );

  getBankWithdrawals = ({ status }) => {
    const params = status !== -1 ? new URLSearchParams({ status }) : null;

    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/cashier_withdrawal_by_payment`,
      params,
      null,
      this.converter.convertBankWithdrawals,
    );
  };

  changeBankWithdrawalStatus = ({ id, status, transactionId }) => {
    return this.sendQuery(
      this.queryTypes.PATCH,
      `${this.baseUrl}/cashier_withdrawal_by_payment`,
      { id, status, id_of_the_transaction: transactionId },
      null,
    );
  };

  getCashierRequisites = () => {
    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/p2p/cashier/requisite`,
      null,
      null,
      this.converter.convertCashierRequisites,
    )
  }

  createCashierRequisite = ({ name, requisite, logo, logoEXT, qr, qrEXT, active, options }) => {
    return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/p2p/cashier/requisite`,
      {
        payment_system: name,
        payment_requisite: requisite,
        payment_logo: logo,
        payment_qr_code: qr,
        payment_logo_ext: logoEXT,
        payment_qr_ext: qrEXT,
        is_active: active,
        options: options,
      },
      null,
      this.converter.convertCashierRequisites,
    )
  }

  patchCashierRequisite = ({ id, name, requisite, logo, logoEXT, qr, qrEXT, active, options }) => {
    return this.sendQuery(
      this.queryTypes.PATCH,
      `${this.baseUrl}/p2p/cashier/requisite`,
      {
        requisite_id: id,
        payment_system: name,
        payment_requisite: requisite,
        payment_logo: logo,
        payment_qr_code: qr,
        payment_ext: logoEXT,
        payment_qr_ext: qrEXT,
        is_active: active,
        options: options,
      },
    )
  }

  deleteCashierRequisite = (id) => {
    return this.sendQuery(
      this.queryTypes.DELETE,
      `${this.baseUrl}/p2p/cashier/requisite`,
      {
        requisite_id: id,
      },
    )
  }

  getDepositsP2P = ({ count, page, from, to, status }) => {
    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/p2p/deposit`,
      { count, page, date_from: from, date_to: to, status },
      null,
      this.converter.convertDepositsP2P,
    )
  }

  patchRequestStatusP2P = ({ id, amount, status, comment }) =>
    this.sendQuery(this.queryTypes.PATCH, `${this.baseUrl}/p2p/deposit`, { id, amount, status, comment });

  getActiveP2Pwithdrawals = ({ count, page, from, to, status }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/p2p/withdrawal`,
      { count, page, date_from: from, date_to: to, status },
      null,
      data => this.converter.convertP2Pwithdrawals(data),
    )

  patchActiveP2Pwithdrawals = (data) =>
    this.sendQuery(
      this.queryTypes.PATCH,
      `${this.baseUrl}/p2p/withdrawal`,
      data,
    )

}

export default PaymentAdminApi;
