import { useCallback } from 'react';

const readFileAsBase64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file || new Blob());
  return new Promise((resolve) => {
    reader.onloadend = () => resolve(reader.result);
  });
};

const readImg = async (files) => {
  if (!files || files.length === 0) {
    return {
      name: '',
      ext: '',
      image: null,
      error: 'hasNoFiles',
    };
  }

  const img = files[0];
  if (img.size > 2300000) {
    return {
      name: img.name,
      ext: '',
      image: null,
      error: 'fileSizeExceed',
    };
  }

  const result = await readFileAsBase64(img);

  if (typeof result === 'string') {
    const arr = result.split(',');
    const extension = arr[0].match(/(jpeg|png|jpg|gif)/i);

    if (!extension) {
      return {
        name: img.name,
        ext: '',
        image: null,
        error: 'fileReadError',
      };
    }

    return {
      name: img.name,
      ext: extension[0],
      image: arr[1],
      error: null,
    };
  }

  return {
    name: img.name,
    ext: '',
    image: null,
    error: 'someError',
  };
};

const useImageInput = () =>
  useCallback(async (e) => {
    const { files } = e.currentTarget;
    return await readImg(files);
  }, []);

export default useImageInput;