import React from 'react';
import block from 'bem-cn';

import PaginatorDropdownSelector from 'components/Paginator/PaginatorDropdownSelector/mobile';

import ActiveDepositRequestsItem from './ActiveDepositRequestsItem/ActiveDepositRequestsItem';
import { useActiveDepositRequests } from 'features/PaymentAdmin/model/useActiveDepositRequests';

import './ActiveDepositRequests.scss';
import ActiveDepositRequestsModal from './ActiveDepositRequestsModal/ActiveDepositRequestsModal';
import ActiveDepositReceiptModal from './ActiveDepositReceiptModal/ActiveDepositReceiptModal';

const b = block('active-deposit-requests-desktop');

const ActiveDepositRequests = () => {
  const { input, output } = useActiveDepositRequests();

  const items = output?.items?.map(item => (
    <ActiveDepositRequestsItem
      key={item.id}
      item={item}
      onReject={() => input.handleItemOpen(item)}
      onReceiptOpen={() => input.handleOpenReceipt(item)}
      onAreYouSure={(key) => input.handleAreYouShure(key)}
      currency={output.currency}
    />
  ));

  return (
    <>
      <div className={b('title')}>{output?.locale?.['/active-deposits']}</div>
      <article className={b()}>
        <ActiveDepositReceiptModal
          item={input?.activeReceipt}
          onClose={input?.handleReceiptClose}
        />
        <ActiveDepositRequestsModal
          onAreYouSure={(key) => input.handleAreYouShure(key)}
          onApproveRequest={(id) => input.handleRequestApprove({id})}
          areYouSure={input.areYouSure}
          item={input.activeItem}
          note={input.values.note}
          onClose={input.handleItemClose}
          onNoteChange={input.handleNoteChange}
          onSend={input.handleRequestReject}
        />
        {items}
      </article>
      <div className={b('paginator')}>
        <PaginatorDropdownSelector
          currentPage={input.values.page}
          pageCount={output.pages}
          onPageClick={input.handlePageChange}
        />
      </div>
    </>
  );
};

export default ActiveDepositRequests;
