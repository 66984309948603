import React from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';
import uploadSVG from '../img/upload.svg';
import useImageInput from '../data/useImageInput';

import './InputPhoto.scss';

const b = block('input-photo-desktop');

const InputPhoto = ({ currentImageData, onImageChange, withIcon = false, ...restProps }) => {
  const handleImgChange = useImageInput();

  return (
    <label className={b({ withIcon })}>
      <input
        className={b('native')}
        type="file"
        accept=".jpeg,.png,.jpg,.gif"
        onChange={async (e) => {
          const imageData = await handleImgChange(e);
          if (onImageChange) onImageChange(imageData);
        }}
      />
      {restProps.children}
      {withIcon && (
        <>
          {currentImageData.error ? (
            <span className={b('error')}>{currentImageData.error}</span>
          ) : (
            <>
              {currentImageData.image || currentImageData.name ? (
                <span className={b('name')}>{currentImageData.name}</span>
              ) : (
                <span className={b('placeholder')}>{restProps.placeholder}</span>
              )}
            </>
          )}
          <SVG className={b('icon')} svgProps={{ svg: uploadSVG }} />
        </>
      )}
    </label>
  );
};

export default InputPhoto;
