export const initialState = {
  isAuth: false,
  name: '',
  balance: 0,
  currency: '',
  role: null,
  id: null,
  channelHolder: false,
  isProcess: false,
  modal: {
    isOpen: false,
    type: 'logout',
  },
};