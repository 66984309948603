import React from 'react';
import block from 'bem-cn';

import PaginatorDropdownSelector from 'components/Paginator/PaginatorDropdownSelector/mobile';

import { useActiveWithdrawalRequests } from '../../../model/useActiveWithdrawalRequests';
import ActiveWithdrawalRequestsItem from './ActiveWithdrawalRequestsItem/ActiveWithdrawalRequestsItem';
import ActiveWithdrawalRequestsModal from './ActiveWithdrawalRequestsModal/ActiveWithdrawalRequestsModal';
import './ActiveWithdrawalRequests.scss';
import BackBlock from '../../../../../components/BackBlock';

const b = block('active-withdrawal-requests-desktop');

const ActiveWithdrawalRequests = () => {
  const { input, output } = useActiveWithdrawalRequests();

  const items = output.items.map(item => (
    <ActiveWithdrawalRequestsItem
      key={item.id}
      item={item}
      onReject={() => input.handleItemOpen(item)}
      onAreYouSure={(key) => input.handleAreYouShure(key)}
      currency={output.currency}
    />
  ));

  return (
    <>
      <div className={b('title')}>{output.locale['/active-requests']}</div>
      <article className={b()}>
        <ActiveWithdrawalRequestsModal
          onAreYouSure={(key) => input.handleAreYouShure(key)}
          onApproveRequest={(id) => input.handleRequestApprove({ id })}
          areYouSure={input.areYouSure}
          item={input.activeItem}
          note={input.values.note}
          selectItems={input.selectItems}
          onClose={input.handleItemClose}
          onNoteChange={input.handleNoteChange}
          onSend={input.handleRequestReject}
          activeSelect={input.activeSelect}
          handleChangeSelect={input.handleChangeSelect}
        />
        {items}
      </article>
      <div className={b('paginator')}>
        <PaginatorDropdownSelector
          currentPage={input.values.page}
          pageCount={output.pages}
          onPageClick={input.handlePageChange}
        />
      </div>
    </>
  );
};

export default ActiveWithdrawalRequests;
