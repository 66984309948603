import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import './PaymentMethodsModal.scss';
import Button from 'components/Button';
const b = block('payment-methods-modal');

const PaymentMethodsModal = ({ isOpen, item, handleAreYouShure, onAccept }) => {
  const locale = useSelector(state => state.locale.locale);

  if (!isOpen) return null;

  return (
    <div className={b()}>
      <div className={b('content')}>
        <>
          <div className={b('header')}>{`${locale.removePayment} "${item.name}" line?`}</div>
          <div className={b('buttons')}>
            <div className={b('button')}>
              <Button color='grey-border' onClick={() => {onAccept(); handleAreYouShure(false)}}>
                {locale.yesRemoveLine}
              </Button>
            </div>
            <div className={b('button')}>
              <Button onClick={() => handleAreYouShure(false)}>
                {locale.cancel}
              </Button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default PaymentMethodsModal;