import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';

import { actions as payment } from 'features/PaymentAdmin';
import { selectItems } from '../data';

export const useActiveWithdrawalRequests = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { actionProcessing, activeWithdrawals } = useSelector(state => state.paymentAdmin, shallowEqual);
  const { currency } = useSelector(state => state.auth, shallowEqual);

  const [activeItem, setActiveItem] = useState(null);
  const [activeSelect, setActiveSelect] = useState('');
  const [areYouSure, setAreYouSure] = useState({
    sureWithdrawal: false,
    sureReject: false,
  });

 

  const [values, setValues] = useState({
    note: '',
    page: 0,
    count: 7,
  });

  const pages = Math.ceil(activeWithdrawals.withdrawals.length / values.count)


  const items = activeWithdrawals.withdrawals.slice(values.page * values.count, (values.page + 1) * values.count).map(item => ({
    ...item,
    date: dayjs(item.date).format('DD.MM.YYYY'),
    amount: `${item.amount} ${currency}`,
  }))


  const handleNoteChange = value => {
    setValues(ps => ({
      ...ps,
      note: value,
    }));
  };

  const handleItemOpen = item => {
    setActiveItem(item);
  };

  const handleItemClose = () => {
    setActiveItem(null);
    setValues(ps => ({ ...ps, note: '' }));
  };

  const handleChangeSelect = (item) => {
    setActiveSelect(selectItems.find(selectItem => selectItem.value === item));
  }

  const handleAreYouShure = (key) => {
    setAreYouSure(ps => ({
      ...ps,
      [key]: !ps[key],
    }));
  }

  const handlePageChange = page => {
    setValues(ps => ({ ...ps, page }));
  };

  const handleActiveRequestsGet = () => {
    dispatch(
      payment.getActiveP2Pwithdrawals({
        page: values.page,
        count: values.count,
        status: 0,
        from: dayjs().subtract(7, 'day').startOf('day').toDate(),
        to: dayjs().endOf('day').toDate(),
      })
    )
  };

  const handleRequestApprove = ({ id }) => {
    dispatch(
      payment.patchActiveP2Pwithdrawals({
        id,
        status: 1,
        callback: () => {
          setValues(ps => ({ ...ps, note: '' }));
          handleActiveRequestsGet();
           setActiveSelect('');
        },
      }),
    );
  };

  const handleRequestReject = () => {
    dispatch(
      payment.patchActiveP2Pwithdrawals({
        id: activeItem.id,
        comment: `Reason: ${activeItem}, note: ${values.note}`,
        status: 2,
        callback: () => {
          setValues(ps => ({ ...ps, note: '' }));
          setActiveItem(null);
          setActiveSelect('');
          handleActiveRequestsGet();
        },
      }),
    );
  };

  useEffect(() => {
    handleActiveRequestsGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.page]);

  return {
    input: {
      values,
      activeItem,
      areYouSure,
      selectItems,
      activeSelect,
      handleNoteChange,
      handleRequestApprove,
      handleRequestReject,
      handleItemOpen,
      handleItemClose,
      handlePageChange,
      handleAreYouShure,
      handleChangeSelect,
    },
    output: {
      locale,
      actionProcessing,
      items,
      pages,
      currency,
    },
  };
};
