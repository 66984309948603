import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export class PaymentAdminConverter {
  convertBanks(data) {
    return data?.payments ?? [];
  }

  convertItem = t => ({
    id: t.id,
    date: dayjs(t.create_date || t.date, 'YYYY-MM-DD:ss').format('YYYY-MM-DD:ss'),
    amount: t.amount,
    status: t.status,
    comment: t.comment,
    paymentSystem: t.payment_system,
    paymentRequisite: t.payment_requisite,
    transactionId: t.id_of_the_transaction,
    imageUrl: `https://bossi.bet${t.img_url}`,
    bank: t.bank,
    requisite: t.requisite,
    playerId: t.player_id ?? '',
  });

  convertActiveRequests = data => {
    const convertRequestsHistoryformattedRequests = data?.data?.map(t => this.convertItem(t));

    return {
      activeRequests: formattedRequests,
      pages: data?.meta?.pages ?? 1,
    };
  };

  convertRequestsHistory = data => {
    const withdrawals = data?.data?.withdrawals?.map(t => this.convertItem(t));
    const deposits = data?.data?.deposits?.map(t => this.convertItem(t));

    return {
      withdrawals,
      deposits,
      pages: data?.meta?.pages ?? 1,
    };
  };

  convertWithdrawalRequestsHistory = data => {
    const withdrawals = data?.data?.map(t => this.convertItem(t));

    return {
      withdrawals,
      pages: data?.meta?.pages ?? 1,
    };
  };

  convertBankWithdrawals = data => {
    return data.sort((a, b) => dayjs(b.create_date).unix() - dayjs(a.create_date).unix());
  };

  convertCashierRequisites = (data) => {
    if (typeof data === 'object' && data !== null) {
      // Если это объект с банками (группировка по типам банков)
      if (Object.values(data).every(Array.isArray)) {
        return Object.values(data).flat().map(item => ({
          id: item.id,
          name: item.payment_system,
          requisite: item.payment_requisite,
          logo: item.payment_logo_url,
          qr: item.payment_qr_code_url || item.payment_qr_code,
          active: item.is_active,
          userId: item.user_id || null,
          userName: item.username || null,
          options: item.options,
        }));
      }

      // Если это одиночный объект банка
      return [{
        id: data.id,
        name: data.payment_system,
        requisite: data.payment_requisite,
        logo: data.payment_logo_url,
        qr: data.payment_qr_code_url || data.payment_qr_code,
        active: data.is_active,
        userId: data.user_id || null,
        userName: data.username || null,
        options: data.options,
      }];
    }
  };

  convertDepositsP2P = data => {
    const items = data.data.map(item => ({
      id: item.id,
      date: item.date,
      username: item.username,
      receipt: item.receipt_url,
      amount: item.amount,
      status: item.status,
      comment: item.comment,
      paymentSystem: item.payment_system,
      paymentRequisite: item.payment_requisite,
      depositTXNID: item.deposit_txn_id,
    }))

    return {
      deposits: items,
      pages: data?.meta?.pages,
    }
  }

  convertP2Pwithdrawals = data => {
    const items = data.map(item => ({
      id: item.id,
      data: item.create_date,
      amount: item.amount,
      status: item.status,
      login: item.login,
      playerId: item.player_id,
    }));

    return {
      withdrawals: items,
    };
  }
}
