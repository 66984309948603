import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'features/PaymentAdmin';
import { extractFileName } from "../data";

export const usePaymentMethods = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { cashierRequisites, actionProcessing } = useSelector(state => state.paymentAdmin);
  const [addMethod, setAddMethod] = useState(false);
  const [requeuedInputs, setRequiredInputs] = useState(true);
  const [areYouSure, setAreYouSure] = useState(false);

  const [values, setValues] = useState({
    id: null,
    name: '',
    requisite: '',
    logo: { ext: '', image: null, error: null, name: '' },
    qr: { ext: '', image: null, error: null, name: '' },
    active: false,
    new: false,
  });

  useEffect(() => {
    dispatch(actions.getCashierRequisites());
  }, [dispatch]);

  useEffect(() => {
    setRequiredInputs(!(values.name.length && values.requisite.length));
  }, [values]);

  const resetValues = () => {
    setValues(ps => ({
      ...ps,
      id: null,
      name: '',
      requisite: '',
      logo: { ext: '', image: '', error: null, name: '' },
      qr: { ext: '', image: '', error: null, name: '' },
      active: false,
      new: true
    }));
    addMethod ? setAddMethod(false) : setAddMethod(true);
  }

  const onClickAddMethod = () => {
    resetValues();
    setAddMethod(true);
  };

  const onChangeLogo = (e) => setValues(ps => ({ ...ps, logo: e }));
  const onChangeQr = (e) => setValues(ps => ({ ...ps, qr: e }));

  const handleDeleteMethod = (id) => dispatch(actions.deleteCashierRequisite(id, resetValues));

  const onSubmitForm = (e) => {
    e.preventDefault();
    const payload = {
      id: values.id,
      name: values.name,
      requisite: values.requisite,
      active: values.active,
    };

    if (values.logo?.image && values.logo?.ext) {
      payload.logo = values.logo.image;
      payload.logoEXT = values.logo.ext;
    }

    if (values.qr?.image && values.qr?.ext) {
      payload.qr = values.qr.image;
      payload.qrEXT = values.qr.ext;
    }
    values.new ? dispatch(actions.createCashierRequisite(payload,resetValues))
      : dispatch(actions.patchCashierRequisite(payload));
  };

  const handleAreYouShure = (flag) => {
    setAreYouSure(flag)
  };

  const handleItemClick = (item) => {
    setAddMethod(true);

    const logo = item?.logo ? extractFileName(item.logo) : "";
    const qr = item?.qr ? extractFileName(item.qr) : "";

    setValues(ps => ({
      ...ps,
      ...item,
      logo: { ...ps.logo, name: logo },
      qr: { ...ps.qr, name: qr },
      new: false
    }));
  };


  return {
    locale,
    cashierRequisites,
    actionProcessing,
    addMethod,
    values,
    requeuedInputs,
    areYouSure,
    onClickAddMethod,
    onChangeLogo,
    onChangeQr,
    handleDeleteMethod,
    onSubmitForm,
    handleItemClick,
    setValues,
    setAddMethod,
    handleAreYouShure,
  };
};
