import React from "react";
import block from "bem-cn";

import { useSelector } from "react-redux";
import arrowSVG from "../img/arrow.svg";

import './PaymentMethodsItem.scss';
import SVG from "components/SVG";


const b = block('payment-methods-item');
const PaymentMethodsItem = ({ item, key, onItemClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const { name } = useSelector(state => state.auth);

  return (
    <div key={key} className={b()}>
      <div className={b('content')}>
        {item?.logo &&
          <div className={b('logo')}>
            <img src={`https://hannibalbet.net/${item.logo}`} alt="logo" />
          </div>
        }
        <div className={b('info')}>
          <div className={b('top')}>
            <div className={b('title')}>{item.name}</div>
            <div className={b('status', { active: item.active })}>{item.active && <span className={b('circle')}>●</span>}{locale[item.active ? 'active' : null]}</div>
          </div>
          <div className={b('bottom')}>
            {locale.author}: {item.userName === name ? locale.you : item.userName}
          </div>
        </div>
      </div>
      <SVG svgProps={{ svg: arrowSVG }} className={b('arrow')} onClick={() => onItemClick(item)} />
    </div>
  )
}

export default PaymentMethodsItem;