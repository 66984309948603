import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import Button from 'components/Button';

import './ActiveDepositRequestsItem.scss';
import SVG from 'components/SVG';

import imgPlaceholder from '../ActiveDepositRequestsModal/img/imgPlaceholder.svg';

const b = block('active-deposit-request-item');

const ActiveDepositRequestsItem = ({ item, onReject, currency, onReceiptOpen, onAreYouSure }) => {
  const locale = useSelector(state => state.locale.locale);

  return (
    <section className={b()}>
      <div className={b('item')}>
        <div className={b('item-text')}>ID</div>
        <div className={b('item-value')}>{item.id}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.date}</div>
        <div className={b('item-value')}>{item.date}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.username}</div>
        <div className={b('item-value')}>{item.username}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.amount}</div>
        <div className={b('item-value', { red: true })}>
          {locale.amount}: <span>{item.amount} {currency}</span>
        </div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.receipt}</div>
        {item?.receipt ? (
          <div className={b('item-value-receipt')} onClick={() => onReceiptOpen()}>
            <SVG svgProps={{ svg: imgPlaceholder }} />
            <span>{locale.showFile}</span>
          </div>
        ) : (
          <div className={b('noReceipt')}>{locale.noReceipt}</div>
        )}
      </div>

      <div className={b('button')}>
        <div className={b('center')}>
          <Button color="transparent" onClick={() => { onReject(); onAreYouSure('sureReject') }}>
            <span className={b('button-text', { red: true })}>{locale.reject}</span>
          </Button>
        </div>
      </div>

      <div className={b('button')}>
        <div className={b('center')}>
          <Button onClick={() => { onReject(); onAreYouSure('sureDeposit') }}>
            <span className={b('button-text')}>{locale.confirm}</span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ActiveDepositRequestsItem;
