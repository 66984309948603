import block from "bem-cn";
import React from "react";
import { useSelector } from "react-redux";

import cross from '../ActiveDepositRequestsModal/img/cross.svg';
import './ActiveDepositReceiptModal.scss';
import SVG from "components/SVG";

const b = block('active-deposit-receipt-modal');
const ActiveDepositReceiptModal = ({ item, onClose }) => {
  const locale = useSelector(state => state.locale.locale);

  if(item === null) return null;

  return (
    <div className={b()}>
      <div className={b('content')}>
        <div className={b('top')}>
          <div>{locale.receipt}</div>
          <SVG svgProps={{ svg: cross }} onClick={onClose} className={b('cross')}/>
        </div>
        <div className={b('image')}>
          <img src={`https://hannibalbet.net/${item.receipt}`} alt="receipt" />
        </div>
      </div>
    </div>
  )
};

export default ActiveDepositReceiptModal;