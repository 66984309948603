export const bankNames = {
  LacalBank: 'Lacal Bank',
  LacalBankR: 'Lacal Bank',
}


export const selectItems = [
  {
    name: 'Incorrect details',
    value: 'Incorrect details',
  },
  {
    name: 'Wrong card number',
    value: 'Wrong card number',
  },
  {
    name: 'Incorrect deposit amount',
    value: 'Incorrect deposit amount',
  },
  {
    name: 'Poor quality photo',
    value: 'Poor quality photo',
  },
  {
    name: 'Other',
    value: 'Other',
  },
];

export const extractFileName = (path) => {
  return path.split('/').pop() || '';
};